import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Emoji, Grid, GridColumn, Heading, Hr, Inline, Layout, List, SEO, Stack, Text } from "../components"

import { SocialLinks } from "../components/SocialLinks/SocialLinks"

const IndexPage = () => {
  // const posts = data.allMdx.edges

  return (
    <Layout>
      <SEO title="Home" />
      <Stack gap="3xl">
        <Stack gap="2xl">
          <Inline gap="sm">
            <StaticImage
              alt="Jon Milner"
              height={96}
              src="../assets/images/me.jpg"
              style={{ borderRadius: `50%`, zIndex: `1` }}
              width={96}
            />
          </Inline>
          <Inline align="bottom" gapX="lg" gapY="lg" justify="between">
            <Heading level={1}>
              Hello,
              <br />
              I'm Jon. <Emoji emoji="👋" />
            </Heading>
            <SocialLinks />
          </Inline>
          <Hr />
          <Stack gap="xl">
            <Text size="lg" theme="secondary">
              I'm <strong>Jon Milner</strong>, a UX Engineer at{` `}
              <strong>Epic Games</strong> in Cary, North Carolina.
            </Text>
            <Text size="lg" theme="secondary">
              I specialize in crafting usable, scalable, accessible, pixel-perfect experiences for the web, and building
              {` `}
              <strong>design systems</strong> that empower teams to deliver higher quality digital experiences faster.
            </Text>
            <Text size="lg" theme="secondary">
              I help teams bridge the gap between <strong>design and development</strong>.
            </Text>
          </Stack>
        </Stack>
        <Stack gap="xl">
          <Heading level={2} size="lg">
            What I Do
          </Heading>
          <Grid breakpoint={600} col={2} gap="xl">
            <GridColumn span={1}>
              <Stack gap="xl">
                <Text color="secondary" size="med" tag="h4" weight="bold">
                  Design
                </Text>
                <List>
                  <span>UX Design</span>
                  <span>Design Systems</span>
                  <span>Interaction Design</span>
                  <span>Responsive Design</span>
                  <span>Prototyping</span>
                </List>
              </Stack>
            </GridColumn>
            <GridColumn span={1}>
              <Stack gap="xl">
                <Text color="secondary" size="med" tag="h4" weight="bold">
                  Development
                </Text>
                <List>
                  <span>CSS</span>
                  <span>Accessible & Semantic HTML</span>
                  <span>JavaScript / TypeScript</span>
                  <span>React</span>
                  <span>Git</span>
                </List>
              </Stack>
            </GridColumn>
          </Grid>
        </Stack>
        {/* <Stack gap="2xl">
          <Heading level={2} size="lg">
            Recent Articles
          </Heading>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug

            return (
              <Link key={node.fields.slug} style={{ display: `block` }} to={node.fields.slug}>
                <Stack gap="med">
                  <Stack gap="xs">
                    <Heading letterSpacing="narrow" size="med">
                      {title}
                    </Heading>
                    {node.frontmatter.date && (
                      <Text size="xs" theme="secondary">
                        {node.frontmatter.date}
                      </Text>
                    )}
                  </Stack>
                  {node.excerpt !== `` && (
                    <Text size="sm">
                      <span dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    </Text>
                  )}
                </Stack>
              </Link>
            )
          })}
        </Stack> */}
      </Stack>
    </Layout>
  )
}

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
